import Vue from 'vue'
import axios from 'axios'

 axios.defaults.baseURL = 'http://39.101.193.112:8071/'
// axios.defaults.baseURL = 'https://localhost:44366/'
//config拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    // 判断是否存在token，如果存在将每个页面header都添加token
      if(!config.headers.shzp_token)
      {
        config.headers.shzp_token = window.localStorage.getItem('shzp_token')
        // config.headers.shzp_token="3C81FAD15504138119358E676312F90376C984C7C2543F51FF73ACE971FC5ACABB68FB9863F91707"
      }
      return config
    })
    // respone拦截器
axios.interceptors.response.use(
      response => {
      // blob类型为文件下载对象，不论是什么请求方式，直接返回文件流数据
      if (response.config.responseType === 'blob') {
     // 返回文件流内容，以及获取文件名, response.headers['content-disposition']的获取, 默认是获取不到的,需要对服务端webapi进行配置
       return Promise.resolve({ data: response.data})
      }
      return response;
      // 依据后端逻辑实际情况，需要弹窗展示友好错误
      },
      error => {
      let resp = error.response
      if (resp.data) {
       console.log('err:' + decodeURIComponent(resp.data)) // for debug
      }
      // TODO: 需要依据后端实际情况判断
      return Promise.reject(error)
      })
      Vue.prototype.$http = axios
      