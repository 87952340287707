<template>
  <div>
    <label>河南就业信息请求权限</label>
    <!-- <label>{{this.querycode}}</label> -->
    <van-overlay :show="loadingshow">
        <div
          style="
            position: fixed;
            top: 50%;
            left: 50%;
            z-index: 100;
            transform: translate(-50%, -50%);
          "
        >
          <van-loading size="24px" vertical color="#0094ff"
            >加载中，请稍后...</van-loading
          >
        </div>
      </van-overlay>
  </div>
  
</template>
<script>
import wx from "weixin-js-sdk";
import qs from 'qs'
export default {
  data() {
    return {
      querycode:"",
      loadingshow:false,
    };
  },
  created(){
    this.loadingshow=true;
    // this.getJssdkConfig();
  },
  mounted(){
     if(!window.localStorage.getItem("shzp_token")){
      // this.login('091Wl3Ga1XA8QE0dHBFa16puiQ0Wl3Gn');
       let query = this.$route.query;
        if (query && query.code) {
          this.querycode=query.code;
          // 获取code调取后端登录接口获取用户信息
           this.login(query.code);
        }
        else{
             this.authorize();
             this.loadingshow=false;
        }   
     }else{
        this.getuserinfo();
     }
  },


  methods: {
    getJssdkConfig() {
      // 调接口获取jssdk配置
      this.request({
        url: "/api/v1/user/get_jssdk",
        data: {
          url: 'http://jiuyeinfo.com/login',
        },
        successFn: (res) => {
          let data = res.data;
          wx.config({
            //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appid, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode", "openLocation", "chooseWXPay"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          /* wx.error(re => {
            console.log(re, "jssdk配置失败");
          }); */
        },
      });
    },
    login(codestr){
        this.$http.get(
      "api/UserMobileInterface/GetWeChatToken",
      {
        params: {
          code: codestr,
        },
      },
    ).then(res => {
      this.loadingshow=false;
      var resdata = res.data;
      if (resdata.Success&&resdata.Data) {
        window.localStorage.setItem('shzp_token',resdata.Data.token);
        if(!resdata.Data.userinfo){
           this.$router.push('/addfrom');
        } 
        else if (resdata.Data.userinfo.UserState&&resdata.Data.userinfo.UserState==2) {
        this.$router.push({ path: '/ReservationResult', query: { userinfo: resdata.Data.userinfo } });
       }
        else if (resdata.Data.userinfo.UserState&&resdata.Data.userinfo.UserState==3) {
          this.$router.push({ path: '/PhysicalResult', query: { userinfo: resdata.Data.userinfo } });
       }
       else if (resdata.Data.userinfo.UserState&&resdata.Data.userinfo.UserState==4) {
          this.$router.push({ path: '/Induction', query: { userinfo: resdata.Data.userinfo } });
       }else{

       }
        
      }else{
        this.$toast.fail(resdata.Msg);
      }

    }).catch(ex => {
      this.loadingshow=false;
      return;
    });
    },
    getuserinfo(){
       this.$http.get(
      "api/UserMobileInterface/GetUserInfo"
    ).then(res => {
      this.loadingshow=false;
      var resdata = res.data;
      // this.$toast.success(resdata.Data.UserState);
      if (!resdata.Success) {
          window.localStorage.clear();
          this.authorize();
          return;
      }
      else if (resdata.Data&&resdata.Data.UserState&&resdata.Data.UserState==1) {
        this.$router.push({ path: '/ReservationResult', query: { userinfo: resdata.Data } });
      }
      else if (resdata.Data&&resdata.Data.UserState&&resdata.Data.UserState==2) {
        this.$router.push({ path: '/ReservationResult', query: { userinfo: resdata.Data } });
      }
      else if (resdata.Data&&resdata.Data.UserState&&resdata.Data.UserState==3) {
        this.$router.push({ path: '/PhysicalResult', query: { userinfo: resdata.Data } });
      }
      else if (resdata.Data&&resdata.Data.UserState&&resdata.Data.UserState==4) {
        this.$router.push({ path: '/Induction', query: { userinfo: resdata.Data } });
      }
      else if(resdata.Data&&resdata.Data.UserState&&resdata.Data.UserState>5){
        this.$router.push({ path: '/Induction', query: { userinfo: resdata.Data } });
      }
      else{
        this.$router.push('/addfrom');
      }

    }).catch(ex => {
      this.loadingshow=false;
      return;
    });
    },
    authorize() {
      // 微信授权登录
     //var redirect_uri = encodeURIComponent(window.location.href);
    //  this.$toast.success(window.location.href);
     var redirect_uri =encodeURIComponent('http://jiuyeinfo.com/login');
      var appid = "wxb18d8d16f19c0e17";
      var wx_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      window.location.href = wx_url;
    },
  },
};
</script>
