import Vue from 'vue';
import { 
    Button,
    Cell, 
    CellGroup,
    Icon,
    Image as VanImage,
    Col, Row,
    Popup,
    Toast,
    Calendar,
    Cascader,
    Checkbox, 
    CheckboxGroup,
    DatetimePicker,
    Field,
    Form,
    PasswordInput, 
    NumberKeyboard,
    Picker,
    RadioGroup, 
    Radio,
    Search,
    Stepper,
    Switch,
    Uploader,
    ActionSheet,
    Dialog,
    DropdownMenu, 
    DropdownItem,
    Loading,
    Notify,
    Overlay,
    PullRefresh,
    ShareSheet,
    SwipeCell,
    Badge,
    Collapse, 
    CollapseItem,
    Divider,
    Empty,
    ImagePreview,
    Lazyload,
    List,
    NoticeBar,
    Popover,
    Skeleton,
    Tag,
    Grid, 
    GridItem,
    IndexBar, 
    IndexAnchor,
    NavBar,
    Pagination,
    Tab, 
    Tabs,
    Tabbar, 
    TabbarItem,
    TreeSelect,
    AddressEdit,
    Area,
    Card,

} from 'vant';



Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Col);
Vue.use(Row);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Calendar);
Vue.use(Cascader);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DatetimePicker);
Vue.use(Field);
Vue.use(Form);
Vue.use(Field);
Vue.use(NumberKeyboard);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Picker);
Vue.use(RadioGroup); 
Vue.use(Radio);
Vue.use(Search);
Vue.use(Stepper);
Vue.use(Switch);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(DropdownMenu); 
Vue.use(DropdownItem);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(PullRefresh);
Vue.use(ShareSheet);
Vue.use(SwipeCell);
Vue.use(Badge);
Vue.use(Collapse); 
Vue.use(CollapseItem);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(ImagePreview);
Vue.use(Lazyload);
Vue.use(List);
Vue.use(NoticeBar);
Vue.use(Popover);
Vue.use(Skeleton);
Vue.use(Tag);
Vue.use(Grid); 
Vue.use(GridItem);
Vue.use(IndexBar); 
Vue.use(IndexAnchor);
Vue.use(NavBar);
Vue.use(Pagination);
Vue.use(Tab); 
Vue.use(Tabs);
Vue.use(Tabbar); 
Vue.use(TabbarItem);
Vue.use(TreeSelect);
Vue.use(AddressEdit);
Vue.use(Area);
Vue.use(Card);