import qs from 'qs'
 //用来获取 调用此js的vue组件实例(this)
 let vm = null;
 const sendThis = (_this)=>{
     vm = _this;
 };
 function getenumdata(enumkeydata,token) {
    vm.$http.get(
       "api/CBUserMobileInterface/GetItemsDetailByKey",
       {
           params: {
               keyStr: enumkeydata,
           },
       },
   ).then(res=>{
     var resdata=res.data;
     if (!resdata.Success) return;
     var enumitems = enumkeydata.split(",");
     enumitems.forEach((element) => {
        vm[element + "Enum"] = resdata.Data.filter((l) => l.ItemKey == element);
       console.log(vm.InsureTypeEnum);
     });
   }).catch(ex=>{
       return;
   });
}
function getenumitemdata(enumkeydata,token) {
    vm.$http.get(
       "api/UserMobileInterface/GetEnumDataByKey",
       {
           params: {
               keyStr: enumkeydata,
           },
       },

   ).then(res=>{
     var resdata=res.data;
     if (!resdata.Success) return;
     var enumitems = enumkeydata.split(",");
     enumitems.forEach((element) => {
        vm[element + "Enum"] = resdata.Data.filter((l) => l.ItemKey == element);
     });
   }).catch(ex=>{
       return;
   });
}
export default {
    sendThis,
    getenumdata,
    getenumitemdata,  
}
