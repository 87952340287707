import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import addfrom from '../views/person/addfrom.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  { path: '/login', name:'login',component: login },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/addfrom',
    name: 'addfrom',
    component: () => import(/* webpackChunkName: "about" */ '../views/person/addfrom.vue')
  },
  {
    path: '/ReservationResult',
    name: 'ReservationResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/person/ReservationResult.vue')
  },
  {
    path: '/PhysicalResult',
    name: 'PhysicalResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/person/PhysicalResult.vue')
  },
  {
    path: '/Induction',
    name: 'Induction',
    component: () => import(/* webpackChunkName: "about" */ '../views/person/Induction.vue')
  },
  {
    path: '/EntryWork',
    name: 'EntryWork',
    component: () => import(/* webpackChunkName: "about" */ '../views/person/EntryWork.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '../components/success.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
   console.log(to);
  next()
})

export default router
