<template>
  <div>
    <van-form>
      <van-field
        label="姓名"
        v-model="userinfo.UserName"
        name="UserName"
        :required="true"
      ></van-field>
      <van-field
        label="身份证号码"
        v-model="userinfo.IDCard"
        name="IDCard"
        @blur="getidcardinfo"
        :required="true"
      ></van-field>
      <van-field label="年龄" v-model="userinfo.Age" name="Age"></van-field>
      <van-field
      readonly
        label="性别"
        v-model="userinfo.Sexname"
        name="Sexname"
        placeholder="点击选择性别"
        @click="selectcilck('Sex')"
      ></van-field>
       
      
      <van-field
        label="民族"
        v-model="userinfo.Nation"
        name="Nation"
        :required="true"
      ></van-field>
      <van-field
        label="籍贯"
        v-model="userinfo.HukouAddress"
        name="HukouAddress"
        :required="true"
      ></van-field>
      <van-field
        readonly
        label="婚姻状况"
        v-model="userinfo.MaritalStatusname"
        name="MaritalStatusname"
        placeholder="点击选择婚姻状况"
        :required="true"
         @click="selectcilck('MaritalStatus')"
      ></van-field>
      <van-field
        label="身高"
        v-model="userinfo.Stature"
        name="Stature"
        :required="true"
      >
      <template #button>厘米</template>
      </van-field>
      <van-field
        label="体重"
        v-model="userinfo.Weight"
        name="Weight"
        :required="true"
      >
      <template #button>KG</template>
      </van-field>
      <van-field
        label="联系电话"
        v-model="userinfo.TelPhone"
        name="TelPhone"
        :required="true"
      ></van-field>
      <van-field
        label="学历"
        v-model="userinfo.Education"
        name="Education"
        :required="true"
      ></van-field>
      <van-field label="专业" v-model="userinfo.Major" name="Major"></van-field>
      <van-field
        label="通讯地址"
        v-model="userinfo.CurrentAddress"
        name="CurrentAddress"
        :required="true"
      ></van-field>
      <van-field
        label="毕业院校"
        v-model="userinfo.GraduateSchool"
        name="GraduateSchool"
      ></van-field>
      <van-field
        label="工作经历"
        v-model="userinfo.WorkExperienceText"
        name="WorkExperienceText"
        type="textarea"
      ></van-field>
      <van-field
        label="个人评价"
        v-model="userinfo.SelfEvaluationText"
        name="SelfEvaluationText"
        type="textarea"
      ></van-field>
    </van-form>
     <van-popup v-model="ItemshowPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="ItemName"
            :columns="ItemEnum"
            @confirm="onConfirm($event, SelectItemName)"
            @change="
              (value, selectedOptions) =>
                onChanage(value, selectedOptions, SelectItemName)
            "
            @cancel="ItemshowPicker = false"
          />
    </van-popup>

    <div style="margin: 16px">
      <van-button round block type="info" @click="onSubmit"
        >提交预约</van-button
      >
    </div>
    <div>
      <van-overlay :show="loadingshow">
        <div
          style="
            position: fixed;
            top: 50%;
            left: 50%;
            z-index: 100;
            transform: translate(-50%, -50%);
          "
        >
          <van-loading size="24px" vertical color="#0094ff"
            >提交中，请稍后...</van-loading
          >
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import qs from "qs";
export default {
  data() {
    return {
      loadingshow: false,
      formdisabled: false,
      enumkeydata: "",
      ItemEnum:"",
      SelectItemName:"",
      ItemshowPicker:false,
      userinfo: {
        ID: "",
        UserName: "",
        IDCard: "",
        EntryDate: "",
        CardType: "",
        DepId: "",
        DepName: "",
        CurrentPostCode: "",
        CurrentPositionName: "",
        Sex: "",
        Birthday: "",
        TelPhone: "",
        EmergencyContact: "",
        EmergencyContactPhone: "",
        Email: "",
        PoliticCountenance: "",
        Nation: "",
        Major: "",
        Education: "",
        BankID: "",
        BankAddress: "",
        GraduateSchool: "",
        GraduateTime: "",
        WorkTime: "",
        IsHaveEducation: "",
        HukouAddress: "",
        CurrentAddress: "",
        UserState: "",
        MaritalStatus: "",
        Remarks: "",
        Age: "",
        Stature: "",
        Weight: "",
        WorkExperienceText: "",
        SelfEvaluationText: "",
        WorkExperience: "",
        SelfEvaluation: "",
      },
      enumkeydata: "Sex,MaritalStatus",
      SexEnum: [],
      MaritalStatusEnum: [],
    };
  },
  created(){
    this.getenumdata();
  },
  methods: {
    onSubmit() {
      this.loadingshow = true;
      var userinfo = this.userinfo;
      userinfo.WorkExperience=encodeURIComponent(userinfo.WorkExperienceText);
      userinfo.SelfEvaluation=encodeURIComponent(userinfo.SelfEvaluationText);
      this.$http
        .post(
          "api/UserMobileInterface/AddUserInfo",
          qs.parse(qs.stringify(userinfo))
        )
        .then((data) => {
          var res = data.data;
          this.loadingshow = false;
          if (res.Success) {
            this.$router.push("/success");
          } else {
            return this.$toast.fail(res.Msg);
          }
        })
        .catch((res) => {
          this.loadingshow = false;
        });
    },
    async getidcardinfo() {
      var userdata = this.userinfo;
      if (userdata.IDCard) {
        const { data: res } = await this.$http.get(
          "api/UserMobileInterface/GetIDCardInfo",
          {
            params: {
              IDCard: userdata.IDCard,
            },
          }
        );
        if (res.Success) {
          userdata.HukouAddress = res.Data.province;
          userdata.Birthday = res.Data.birthday;
          userdata.Sex = res.Data.sex;
          userdata.Sexname = res.Data.sex==0?"女":"男";
          userdata.Age = res.Data.age;
        } else {
          this.$message.error("该身份证号码有误!");
        }
      }
    },
    getenumdata() {
      var that = this;
      that.$common.sendThis(that);
      that.$common.getenumitemdata(that.enumkeydata, that.userToken);
    },
    selectcilck(itemname){
      this.ItemshowPicker=true;
      this.ItemEnum=this[itemname+"Enum"];
      this.SelectItemName=itemname;
    },
    onConfirm(item, str) {
      this.setselvalue(item, str);
    },
    onChanage(item, selectedOptions, str) {
      this.setselvalue(selectedOptions, str);
    },
    setselvalue(item, str) {
      this.userinfo[str] = item.ItemCode;
      this.userinfo[str + "name"] = item.ItemName;
      this.ItemshowPicker= false;
    },
  },
};
</script>
